<div class="flex justify-center items-start search-bar-wrapper gap-2">
  <div class="w-full">
    <div class="flex search-bar-section gap-3 w-full h-full">
      <span class="material-symbols-outlined" style="color: #9e9e9e; font-size: 21px;">
        search
        </span>
      <input
        class="search-bar"
        type="text"
        autofocus
        placeholder="Search product"
        (ngModelChange)="setAutoCompleteValue()"
        (keydown.enter)="setSearchValue()"
        [formControl]="inputFrmCnt"
      />
      @if (searchValue) {
        <span
          class="material-symbols-outlined cursor-pointer"
          style="color: #282E38; font-size: 21px;"
          (click)="deleteSearchValue()"
        >close</span>
      }
    </div>
    @if ((searchValue.length == 0 || (searchValue.length > 0 && hasInput))  && searchHistory.length > 0) {
      <div class="w-full search-history-content flex flex-col">
        <div class="search-history-title w-full mt-2">Search history</div>
        @for (item of searchHistory; track item; let i = $index) {
        <div class="search-item-section flex flex-row items-center justify-between w-full">
          <i class="pi pi-history"></i>
          <div class="search-item cursor-pointer" (click)="setSearchValueFromHistory(item)">{{ item }}</div>
          <span 
            class="material-symbols-rounded cursor-pointer" 
            (click)="delOneSearchHistory(i)"
            style="font-size: 17px; color: #282E38;"
          >
            close
          </span>
        </div>
        }
      </div>
    }
    @if (searchValue.length  > 0 && (autoComplete && autoComplete.length > 0 || recommendProd.length > 0)) {
      <div class="w-full search-content gap-4">
        <div class="w-full gap-4">
          @for (item of autoComplete; track item; let i = $index) {
          <div class="search-item-section cursor-pointer flex flex-row items-center justify-between w-full"
            (click)="setSearchValueFromHistory(item)">
            <div class="autoComplete-item"
              [innerHTML]="replaceSearchPartToBold(searchValue, item)">
            </div>
            <i class="pi pi-external-link cursor-pointer" style="color: #9e9e9e"></i>
          </div>
          }
        </div>

         @if (recommendProd.length > 0) {
          <div class="flex flex-col w-full">
            <div class="search-history-title w-full">Recommended for you</div>
            @for (item of recommendProd; track item; let i = $index) {
              <div class="recommond-item cursor-pointer flex flex-row items-center justify-between w-full gap-6" (click)="goToProductDetail(item)">
                <div class="recommond-left flex flex-row gap-2 items-center">
                  <img class="product-img" [src]="item.mainImage?.uri" />
                  <div class="product-item-title">{{ item.title }}</div>
                </div>
                <i class="pi pi-external-link" style="color:#9E9E9E" ></i>
              </div>
            }
          </div>
         }
      </div>
    }
  </div>

</div>