<div class="search-history-title w-full">Recommended for you</div>
@for (item of recommendProd; track item; let i = $index) {
  <div class="recommond-item flex flex-row items-center justify-between w-full gap-6">
    <div class="recommond-left flex flex-row gap-2 items-center">
      <img class="product-img" [src]="item.mainImage?.uri" />
      <div class="product-item-title">{{ item.title }}</div>
    </div>
    <i class="pi pi-external-link cursor-pointer" style="color:#9E9E9E" (click)="gotoThisProduct(item)"></i>
  </div>
}
