import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  DialogService,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { Subject } from 'rxjs';
import { PageRegisterComponent, REGISTER_STEP } from '../../../amm-pages/page-register/page-register.component';
import { APPCONSTANT, MATERIAL_ICON_NAME } from '../../constants';
import {
  AuthService,
  GoogleApiService,
  ProductFilterService,
  ProductService,
  RbacService,
  StorageService,
} from '../../services';

import { CommonModule } from '@angular/common';
import { UseraccountModel } from '../../models';
import { AuthStore, SearchProductStore, ShoppingInfoStore } from '../../stores';
import { MessagingStore } from '../../stores/amm-message.store';
import { AmmButtonWithBageComponent } from '../amm-button-with-bage/amm-button-with-bage.component';
import { GlobalSearchComponent } from '../global-search/global-search.component';
import { FormsModule } from '@angular/forms';


@Component({
  selector: 'amm-global-nav-bar',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    AmmButtonWithBageComponent,
    OverlayPanelModule,
    DynamicDialogModule,
    PageRegisterComponent,
    FormsModule,
],
  templateUrl: './amm-global-nav-bar.component.html',
  styleUrl: './amm-global-nav-bar.component.scss',
  providers: [],
})
export class AmmGlobalNavBarComponent implements OnInit {
  @Input()
  showFilterButton?: boolean;
  @Input()
  showFilterPanel?: boolean;
  shouldExploreBtnActiveStyle: boolean = false;
  @Output()
  searchKey = new EventEmitter<string>();
  @Output()
  showFilter = new EventEmitter<boolean>();
  public iconName = MATERIAL_ICON_NAME;
  public messageStore = inject(MessagingStore);
  public shopInfoStore = inject(ShoppingInfoStore);
  public authStore = inject(AuthStore);
  public authService = inject(AuthService);
  private dialogService = inject(DialogService);
  public storageService = inject(StorageService);
  public productService: ProductService = inject(ProductService);
  public googleLoginService = inject(GoogleApiService);
  public _rbacService = inject(RbacService);
  public googleAuthService=inject(AuthService);
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private ref: DynamicDialogRef | undefined;
  private _router: Router = inject(Router);
  public isFavoritePage: boolean = false;
  public isOrdersPage: boolean = false;
  public isShoppingCartPage: boolean = false;
  // public searchValue: string = "";
  get searchValue() {
    return this.searchProductStore.searchKeyWord();
  }
  set searchValue(term: string) {
    this.searchProductStore.setSearchKey(term);
  }

  get loginAccount(): UseraccountModel {
    return this.storageService.getLocalItem(APPCONSTANT.BUYER_INFO);
  }

  public searchProductStore = inject(SearchProductStore);
  private productFilterService = inject(ProductFilterService);
  constructor(private route: ActivatedRoute) {}

  public dropdownItemArrays = [
    [
      {
        leftIconName: MATERIAL_ICON_NAME.orders,
        label: 'Orders',
      },
      {
        leftIconName: MATERIAL_ICON_NAME.mail,
        label: 'Messages',
      },
      {
        leftIconName: MATERIAL_ICON_NAME.help,
        label: 'Help center',
      },
    ],
    [
      {
        leftIconName: MATERIAL_ICON_NAME.lock,
        label: 'Login & Security',
      },
      {
        leftIconName: MATERIAL_ICON_NAME.location_on,
        label: 'Addresses',
      },
    ],
    [
      {
        leftIconName: MATERIAL_ICON_NAME.logout,
        label: 'Log out',
      },
    ],
  ];

  get avatar(): string {
    return this.storageService.getLocalItem('avatar');
  }

  ngOnInit(): void {
    this._btnStyleJudgement();

    const inSearchPage = this._router.url.includes("search-result");
    if (inSearchPage) {
      if (this.searchProductStore.searchKeyWord().length > 0) {
        this.searchValue = this.searchProductStore.searchKeyWord();

      } else {
        const searchHistory = this.storageService.getLocalItem('searchHistory') || [];
        this.searchValue = searchHistory[0];
        this.searchProductStore.setSearchKey(this.searchValue);
        this.searchProductStore.showFilterPanelBtn();
      }

    } 

    this._router.events.subscribe(() => {
      this._btnStyleJudgement();
    });
    this.googleAuthService.googleRoleErr$.subscribe((error:string)=>{
      if (error){
        this.ref=this.dialogService.open(PageRegisterComponent, {
          data: {
            step:REGISTER_STEP.LOGINEMAIL,
            errorInfo:error
        },
          showHeader: false,
          // closable:true,
          width: '760px',
          style: {
            padding: '0px',
            'border-radius': '24px',
            width: '760px',
            'min-height': '532px',
          },
          contentStyle: {
            overflow: 'visible',
            padding: '0px',
            'border-radius': '24px',
            width: '760px',
            'min-height': '532px',
          },
          breakpoints: {
            '760px': '95vw',
          },
        });
      }
    })

  }

  private _btnStyleJudgement(): void {
    this.isFavoritePage = false;
    this.isOrdersPage = false;
    this.isShoppingCartPage = false;

    if (this._router.url.includes('favorites')) {
      this.isFavoritePage = true;
      this.shouldExploreBtnActiveStyle = false;
    } else if (this._router.url.includes('shopping-carts')) {
      this.isShoppingCartPage = true;
      this.shouldExploreBtnActiveStyle = false;
    } else if (this._router.url.includes('orders')) {
      this.isOrdersPage = true;
      this.shouldExploreBtnActiveStyle = false;
    } else if (this._router.url === '/home') {
      this.shouldExploreBtnActiveStyle = true;
    } else {
      this.shouldExploreBtnActiveStyle = false;
    }
  }

  ngAfterViewInit() {
    var _fragment: any = null;
    this.route.fragment.subscribe((fragment) => {
      _fragment = fragment;
    });

    if (_fragment) {
      this.googleLoginService.initGoogleAPI();
    }
  }

  isLoggedIn() {
    var isLoggedIn = this.authService.isLoggedIn();
    return isLoggedIn;
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }
  onButtonClick(event: string) {
    switch (event) {
      case MATERIAL_ICON_NAME.favorite:
        this._router.navigate([
          'home',
          { outlets: { mainBody: ['favorites'] } },
        ]);
        break;
      case MATERIAL_ICON_NAME.shoppingCart:
        this._router.navigate([
          'home',
          { outlets: { mainBody: ['shopping-carts'] } },
        ]);
        break;
      case MATERIAL_ICON_NAME.orders:
        this._router.navigate(['home', { outlets: { mainBody: ['orders'] } }]);
        break;
      case MATERIAL_ICON_NAME.mail:
        this._router.navigate([
          { outlets: { primary: ['message'], mainBody: null } },
        ]);
        break;
      case MATERIAL_ICON_NAME.help:
        this._router.navigate([
          { outlets: { primary: ['help-center'], mainBody: null } },
        ]);
        break;
      case MATERIAL_ICON_NAME.lock:
        this._router.navigate([
          'home',
          { outlets: { mainBody: ['login-security'] } },
        ]);
        break;
      case MATERIAL_ICON_NAME.location_on:
        this._router.navigate(['home', { outlets: { mainBody: ['address'] } }]);
        break;
      case MATERIAL_ICON_NAME.logout:
        if (this.searchProductStore.searchKeyWord().length > 0) {
          this.searchProductStore.resetSearch();
        }
        this.authService.logOut();
        break;
    }
  }

  onLogin() {
    this.dialogService.open(PageRegisterComponent, {
      showHeader: false,
      // closable:true,
      width: '760px',
      style: {
        padding: '0px',
        'border-radius': '24px',
        width: '760px',
        'min-height': '532px',
      },
      contentStyle: {
        overflow: 'visible',
        padding: '0px',
        'border-radius': '24px',
        width: '760px',
        'min-height': '532px',
      },
      breakpoints: {
        '760px': '95vw',
      },
    });
  }
  onExplore() {
    this.searchValue = "";
    this.searchProductStore.resetSearch();
    this._router.navigate(['home', { outlets: { mainBody: null } }]);
  }

  onFocusSearchInput(): void {
    const searchDialogRef = this.dialogService.open(GlobalSearchComponent, {
      data: {
        searchValue: this.searchValue
      },
      closable: true,
      dismissableMask: true,
      showHeader: false,
      closeOnEscape: true,
      modal: true,
      position: 'top',
      width: '680px',
      style: {
        padding: '0px',
        'border-radius': '32px',
        'max-width': '680px',
        'min-height': '62px', // 46 + 16
        margin: '0px',
        'margin-top': '30px', // 30
        'margin-left': '-40px',
      },
      contentStyle: {
        // overflow: 'visible',
        padding: '0px',
        'border-radius': '32px',
        'max-width': '680px',
        'min-height': '62px',
        // 'background-color': 'pink'
      },
      breakpoints: {
        // '1280px': '70vw', //'90vw',
        // '1024px': '70vw',
        // '780px': '30vw',
      },
    });

    searchDialogRef.onClose.subscribe((res) => {
      if (res) {
        this.searchValue = res.searchValue;
  
        if (this.searchValue.length > 0) {
          this.searchProductStore.showFilterPanelBtn();
          this._router.navigate(['home', { outlets: { mainBody: ['search-result'] } }]);
          
        } else {
          this.searchProductStore.hideFilterPanelBtn();
          this.searchProductStore.hideFilterPanel();
          this.productFilterService.clearSearchFilter$.next(true);
          this.productFilterService.searchKey$.next('');
          this._router.navigate(['home', { outlets: { mainBody: null } }]);
        }
      }

    })
  }

  deleteSearchInput(): void {
    this.searchValue = '';
    this.searchProductStore.resetSearch();
    this._router.navigate(['home', { outlets: { mainBody: null } }]);
  }

  showHideFilter(): void {
    this.searchProductStore.toggleFilterPanel();
  }

}
