import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { Subject } from 'rxjs';
import { MAX_AUTOCOMPLETE_ITEMS_ON_SEARCH, MAX_SEARCH_HISTORY_PRESERVED } from '../../constants';
import {
  ProductFilterService,
  ProductService,
  StorageService,
} from '../../services';
import { SearchProductStore } from '../../stores';
import { SearchRecommandComponent } from '../search-recommand/search-recommand.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Product } from '../../models/catalog.model';
import { Router } from '@angular/router';

@Component({
  selector: 'amm-global-search',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    OverlayPanelModule,
    DividerModule,
    ButtonModule,
    SearchRecommandComponent,
  ],
  templateUrl: './global-search.component.html',
  styleUrl: './global-search.component.scss',
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
  get searchValue() {
    return this.searchProductStore.searchKeyWord();
  }
  set searchValue(term: string) {
    this.searchProductStore.setSearchKey(term);
  }
  public autoComplete?: Array<string>;
  public searchHistory: Array<string> = [];
  public inputFrmCnt: FormControl<string | null> = new FormControl('');
  public hasInput: boolean = false;
  public recommendProd: Array<Product> = [];

  public storageService: StorageService = inject(StorageService);
  public productService: ProductService = inject(ProductService);
  public searchProductStore = inject(SearchProductStore);
  private productFilterService = inject(ProductFilterService);
  private destory$: Subject<boolean> = new Subject<boolean>();
  private sanitizer = inject(DomSanitizer);
  private router: Router = inject(Router);
  private dialogConfig = inject(DynamicDialogConfig<GlobalSearchComponent>);

  constructor(public ref: DynamicDialogRef<GlobalSearchComponent>) {}
  
  
  ngOnInit(): void {
    this.inputFrmCnt.setValue(this.dialogConfig.data.searchValue);
    this.searchValue = this.inputFrmCnt.value || '';
    this.hasInput = (this.inputFrmCnt.value?.length || 0) > 0;
    this.searchHistory = this.storageService.getLocalItem('searchHistory') || [];
    this.recommendProd = [];

    this.inputFrmCnt.valueChanges.subscribe(() => {
      this.searchValue = this.inputFrmCnt.value || '';
      if (this.hasInput) {
        this.hasInput = false;
      }
    })
  }
  ngOnDestroy(): void {
    this.destory$.next(true);
    this.destory$.complete();
  }

  setSearchValue(): void {
    this.searchProductStore.setLoading(true);
    if (this.searchValue) {
      if (this.searchHistory.includes(this.searchValue)) {
        this.searchHistory = this.searchHistory.filter(v => v != this.searchValue);
        this.searchHistory = [this.searchValue, ...this.searchHistory];
        this.storageService.setLocalItem('searchHistory', this.searchHistory);

      } else {
        this.searchHistory = [this.searchValue, ...this.searchHistory];
        if (this.searchHistory.length > MAX_SEARCH_HISTORY_PRESERVED) {
          this.searchHistory = this.searchHistory.slice(
            0,
            this.searchHistory.length - 1,
          );
        }
        this.storageService.setLocalItem('searchHistory', this.searchHistory);
      }
    }
    this.productFilterService.searchKey$.next(this.searchValue);
    // this.router.navigate(['home', { outlets: { mainBody: ['search-result'] } }]);

    this.ref.close({
      searchValue: this.searchValue,
    });
  }

  setAutoCompleteValue(): void {
    if (this.searchValue.length > 1 && this.searchValue.trimStart().length > 0) {
      this.productService
        .getAutoComplete(this.searchValue)
        .subscribe((autoC) => {
          this.autoComplete = autoC.splice(0, MAX_AUTOCOMPLETE_ITEMS_ON_SEARCH);
        });

      this.productService
        .getProducts(this.searchValue, '', 0, 4)
        .subscribe((res) => {
          this.recommendProd = res.dataList || [];
        });

    }
  }

  delOneSearchHistory(i: number): void {
    this.searchHistory.splice(i, 1);
    this.storageService.setLocalItem('searchHistory', this.searchHistory);
  }

  deleteSearchValue(): void {
    this.inputFrmCnt.setValue('');
    this.ref.close({
      searchValue: '',
    });
  }
  setSearchValueFromHistory(item: string): void {
    this.searchProductStore.setLoading(true);
    this.searchValue = item;
    this.searchHistory = this.searchHistory.filter(v => v != this.searchValue);
    this.searchHistory = [this.searchValue, ...this.searchHistory];
    this.storageService.setLocalItem('searchHistory', this.searchHistory);
    this.searchProductStore.showFilterPanelBtn();
    this.productFilterService.searchKey$.next(this.searchValue);

    this.ref.close({
      searchValue: this.searchValue,
    });
  }

  replaceSearchPartToBold(userInput: string, replaceString: string): any {
    let regex = new RegExp(userInput, 'gi');
    let result = replaceString.replace(regex, function (match) {
      return `<span style="color:#282E38">${match}</span>`;
    });

    return this.sanitizer.bypassSecurityTrustHtml(result);
  }


  goToProductDetail(p: Product): void {
    this.inputFrmCnt.setValue('');
    this.searchProductStore.resetSearch();

    this.ref.close({
      searchValue: this.searchValue,
    });

    this.router.navigate([
      'home',{outlets: {mainBody: [
            'details',
            (p.variantMerchs?.length || 0) > 0 ? p.code : p.id,
          ],
        },
      },
    ]);
  }
}
